import PngImage from "../assets/img/png-image.svg";
import JpegImage from "../assets/img/jpeg-image.svg";
import PdfImage from "../assets/img/pdf-image.svg";
import JpgImage from "../assets/img/jpg-image.svg";
import DocxImage from "../assets/img/docx-image.svg";
import XlsxImage from "../assets/img/xlsx-image.svg";
import FileAllImage from "../assets/img/file-all-image.svg";
import PngImageMobile from "../assets/img/svg/png-image-mobile.svg";
import JpegImageMobile from "../assets/img/svg/jpeg-image-mobile.svg";
import PdfImageMobile from "../assets/img/svg/pdf-image-mobile.svg";
import JpgImageMobile from "../assets/img/svg/jpg-image-mobile.svg";
import DocxImageMobile from "../assets/img/svg/docx-image-mobile.svg";
import XlsxImageMobile from "../assets/img/svg/xlsx-image-mobile.svg";
import FileAllImageMobile from "../assets/img/svg/file-all-image-mobile.svg";
import dayjs from "dayjs";
import { TICKETS_PER_PAGE, MAX_FILE_SIZE, FILE_TYPES } from "../const/const";

export const getFileImage = (extention) => {
  switch (extention) {
    case FILE_TYPES.PNG:
      return PngImage;
    case FILE_TYPES.JPEG:
      return JpegImage;
    case FILE_TYPES.JPG:
      return JpgImage;
    case FILE_TYPES.PDF:
      return PdfImage;
    case FILE_TYPES.DOCX:
      return DocxImage;
    case FILE_TYPES.XLSX:
      return XlsxImage;
    default:
      return FileAllImage;
  }
};

export const getFileImageMobile = (extention) => {
  switch (extention) {
    case FILE_TYPES.PNG:
      return PngImageMobile;
    case FILE_TYPES.JPEG:
      return JpegImageMobile;
    case FILE_TYPES.JPG:
      return JpgImageMobile;
    case FILE_TYPES.PDF:
      return PdfImageMobile;
    case FILE_TYPES.DOCX:
      return DocxImageMobile;
    case FILE_TYPES.XLSX:
      return XlsxImageMobile;
    default:
      return FileAllImageMobile;
  }
};

export const isTypeValid = (extention) => {
  return Object.values(FILE_TYPES).includes(extention);
};

export const lpuAdapter = (lpu) => {
  return {
    position: {
      lat: parseFloat(lpu.lat),
      lng: parseFloat(lpu.lng),
    },
    adress: lpu.address,
    ageCategory: lpu.age_category,
    category_id: lpu.category_id === null ? "" : `${lpu.category_id}`,
    fullName: lpu.full_name,
    link: lpu.link,
    name: lpu.name,
    ownership: lpu.ownership,
    title: lpu.title,
    icon: null,
    specialization: lpu.specialization,
    coverage: lpu.coverage,
  };
};

export const refundTicketsListAdapter = (ticket) => {
  return {
    date: dayjs(ticket.created_at * 1000).format("DD.MM.YYYY"),
    ticketNumber: ticket.id,
    status: ticket.status,
    iban: ticket.iban,
    refundAmount: ticket.refund_amount,
    refundDate: dayjs(ticket.refund_date).format("DD.MM.YYYY"),
    refundDateMilisec: ticket.refund_date,
    fileList: "",
    fileOrder: ticket.file_order,
    filesAct: ticket.files_act,
    filesCopyInn: ticket.files_copy_inn,
    filesCopyInsurance: ticket.files_copy_insurance,
    filesCopyPassport: ticket.files_copy_passport,
    filesMedicalReturn: ticket.files_medical_return,
    filesPayMentReceipt: ticket.files_payment_receipt,
  };
};

export const visitListAdapter = (visit) => {
  return {
    id: visit.id,
    date: formatDate(visit.date),
    contractNumber: visit.contract_number,
    program: visit.program,
    medicalFacility: visit.medical_facility,
    medicalCareGeneral: visit.medical_care_general,
    medicalCareDetailed: visit.medical_care_detailed,
    city: visit.city,
    price: visit.price,
    createdAt: formatDate(visit.created_at * 1000),
  };
};

export const lpuFilterByCategory = (lpuList, lpuCategories, categories) => {
  let filteredCategories = lpuCategories.filter((item) =>
    categories.includes(item.id)
  );
  const filteredSpecializations = lpuList.filter((specialization) => {
    const clinicNames = filteredCategories.map((clinic) => clinic.name);
    return clinicNames.some((clinicName) =>
      specialization.specialization.includes(clinicName)
    );
  });
  return filteredSpecializations;
  // return lpuList.filter((item) => categories.includes(item.category_id))
};

export const searchLpuByName = (lpu, searchValue) => {
  return lpu.filter((item) => {
    if (item.name) {
      return item.name.toUpperCase().indexOf(searchValue.toUpperCase()) >= 0;
    }

    return false;
  });
};

export const searchByAddressAndName = (lpu, searchValue) => {
  const lowerCaseQuery = searchValue.toLowerCase();
  return lpu.filter((item) => {
    const lowerCaseAddress = item.adress.toLowerCase();
    const lowerCaseName = item.name.toLowerCase();

    return (
      lowerCaseAddress.includes(lowerCaseQuery) ||
      lowerCaseName.includes(lowerCaseQuery)
    );
  });
};

export const getPaginationArray = (dataArray) => {
  let pagesNumber = 0;

  if (dataArray !== null) {
    pagesNumber = Math.ceil(dataArray.length / TICKETS_PER_PAGE);
  }

  let paginationArray = [];

  for (var i = 0; i < pagesNumber; i++) {
    paginationArray.push(i + 1);
  }

  return paginationArray;
};

export const getArrayPerPage = (dataArray, itemsOnPage, currentPage) => {
  return dataArray
    .filter((item) => {
      if (
        dataArray.indexOf(item) >= (currentPage - 1) * itemsOnPage &&
        dataArray.indexOf(item) < currentPage * itemsOnPage
      ) {
        return true;
      }
      return false;
    })
    .slice();
};

export const formatDate = (dateValue) => {
  if (typeof dateValue !== "string") {
    return dayjs(dateValue).format("DD.MM.YYYY");
  } else if (dateValue && dateValue.length < 25) {
    return dateValue?.substr(0, 10);
  }
};

export const formatDateForm = (dateValue) => {
  return dayjs(dateValue).format("YYYY-MM-DD HH:mm:ss");
};

export const sortRefundTickets = (ticket1, ticket2) => {
  return ticket2.ticketNumber - ticket1.ticketNumber;
};

export const sortVisitList = (visit1, visit2) => {
  return visit2.id - visit1.id;
};

export const sortPaidTickets = (ticket1, ticket2) => {
  const date2 = new Date(ticket2.refundDateMilisec);
  const date1 = new Date(ticket1.refundDateMilisec);

  return date2.getTime() - date1.getTime();
};

export const sortByDistance = (array, myLatitude, myLongitude) => {
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  return array.sort((a, b) => {
    const distanceA = calculateDistance(
      myLatitude,
      myLongitude,
      a.position.lat,
      a.position.lng
    );
    const distanceB = calculateDistance(
      myLatitude,
      myLongitude,
      b.position.lat,
      b.position.lng
    );
    return distanceA - distanceB;
  });
};

export const isFileSiZeAllowed = (filesArray) => {
  const overSizeElement = filesArray.find((item) => {
    return item.size > MAX_FILE_SIZE;
  });

  if (overSizeElement === undefined) {
    return true;
  }

  return false;
};

export const isIos = () => {
  let is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (
    navigator.userAgent.indexOf("iPhone") >= 0 ||
    navigator.userAgent.indexOf("iPod") >= 0 ||
    navigator.userAgent.indexOf("iPad") >= 0 ||
    navigator.userAgent.indexOf("Macintosh") >= 0
  ) {
    is_safari = true;
  }

  return is_safari;
};
