import React, { Component } from "react";
import logo from "../../assets/img/logo_color.png";
import ApiService from "../../service/api/api.service";
import { TokenService } from "../../service/token/storage.service";
import InputMask from "react-input-mask";
import { isTel, transformTelNumber } from "../validator/tel.validator";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { BackButton } from "./backButton";
import "./login.scss";

class Register extends Component {
  state = {
    polis: "",
    tel: "",
    sms: "",
    smsFieldShow: false,
    isFormError: false,
    polisErrorText: "",
    telErrorText: "",
    smsErrorText: "",
  };

  showSMSField = () => {
    this.setState({ smsFieldShow: true, isFormError: false });
  };

  handleInput = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  sendSMSCode = () => {
    if (this.state.polis.length !== 0 && this.state.tel.length !== 0) {
      if (isTel(this.state.tel)) {
        let data = {
          query: `query {
                              authRegister(polis: "${
                                this.state.polis
                              }", phone: "${transformTelNumber(
            this.state.tel
          )}") {
                                success,
                                message,
                              }
                            }`,
        };

        trackPromise(
          ApiService.post("/graphql", data)
            .then((response) => {
              const { data } = response.data;
              const { showToast } = this.props;

              if (data.authRegister.success) {
                showToast("success", data.authRegister.message);
                this.showSMSField();
              } else {
                this.setState({
                  isFormError: true,
                  telErrorText: "",
                  polisErrorText: data.authRegister.message,
                });
              }
            })
            .catch((err) => console.error(err))
        );
      } else {
        this.setState({
          isFormError: true,
          telErrorText: "Невірно вказаний номер",
        });
      }
    } else {
      this.setState({
        isFormError: true,
        polisErrorText: "Поле обов'язкове для заповнення",
        telErrorText: "Поле обов'язкове для заповнення",
      });
    }
  };

  submitRegisterForm = () => {
    if (
      this.state.polis.length !== 0 &&
      this.state.tel.length !== 0 &&
      this.state.sms.length !== 0
    ) {
      let data = {
        query: `query {
                            authSms(polis: "${this.state.polis}", sms: "${this.state.sms}") {
                            success,
                            message,
                            access_token
                          }
                        }`,
      };

      trackPromise(
        ApiService.post("/graphql", data)
          .then((response) => {
            const { data } = response.data;

            if (data.authSms.success) {
              TokenService.saveTempToken(data.authSms.access_token);
              this.props.history.push("/password");
            } else {
              this.setState({
                isFormError: true,
                smsErrorText: data.authSms.message,
              });
            }
          })
          .catch((err) => console.error(err))
      );
    } else {
      this.setState({
        isFormError: true,
        smsErrorText: "Поле обов'язкове для заповнення",
      });
    }
  };

  clearError = () => {
    this.setState({ isFormError: false });
  };

  handleKeydown = (event) => {
    if (event.keyCode === 13) {
      this.submitRegisterForm();
    }
  };

  render() {
    const {
      smsFieldShow,
      isFormError,
      polisErrorText,
      telErrorText,
      smsErrorText,
    } = this.state;

    return (
      <div className="check_in">
        <div className="check_in__logo">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="caption">Реєстрація</h1>
        <p className="desc">
          Ласкаво просимо до Вашого особистого кабінету. Для реєстрації, будь
          ласка, заповніть поля нижче
        </p>
        <form
          className={`check_in__form registration__form ${
            isFormError ? "error" : ""
          } `}
        >
          <div className="check_in__form__row">
            <label>Номер полiсу або ІПН:</label>
            <input
              placeholder="№"
              name="polis"
              required
              onChange={this.handleInput}
              onFocus={this.clearError}
            />
            <div className="help__error">{polisErrorText}</div>
          </div>
          <div className="check_in__form__row">
            <label>Номер телефону:</label>
            <InputMask
              name="tel"
              type="tel"
              placeholder="+38"
              mask="+38(999)-999-99-99"
              required
              onFocus={this.clearError}
              onChange={this.handleInput}
            />
            <div className="help__error">{telErrorText}</div>
          </div>
          {!smsFieldShow && (
            <div
              onClick={this.sendSMSCode}
              className="button button__silver button__sms"
            >
              Надіслати код в SMS повідомленні
            </div>
          )}

          {smsFieldShow && (
            <div className="fade-element">
              <div className="check_in__form__row">
                <label>Код SMS</label>
                <input
                  name="sms"
                  type="password"
                  required
                  onChange={this.handleInput}
                  onFocus={this.clearError}
                  onKeyDown={this.handleKeydown}
                />
                <div className="help__error">{smsErrorText}</div>
              </div>
              <div className="link__other">
                <div className="link__other__row">
                  <span className="pointer" onClick={this.sendSMSCode}>
                    Відправити SMS повторно
                  </span>
                </div>
              </div>
              <button
                type="button"
                onClick={this.submitRegisterForm}
                className="button button__success"
              >
                Зареєструватися
              </button>
              <div className="help__block__error">
                <span>Якщо у Вас виникла проблема при реєстрації</span>
                <span>Будь ласка, зателефонуйте на номер</span>
                <a href="tel:+38(044)290-71-71">+38(044)290-71-71</a>
              </div>
            </div>
          )}
          <div className="link__other">
            <div className="link__other__row">
              <span>Маєте акаунт?</span>
              <Link to={`/login`}>Авторизуватись</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
